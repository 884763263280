import React, { useState } from 'react';
import {
  useFloating,
  Placement,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow,
  useHover,
  useInteractions,
  useRole,
  useDismiss,
} from '@floating-ui/react';

type CustomTooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  placement?: Placement;
};

const CustomTooltip = ({
  children,
  content,
  placement = 'top',
}: CustomTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [offset(8), flip(), shift(), arrow({ element: arrowElement })],
    whileElementsMounted: autoUpdate,
  });

  const hover = useHover(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className="inline-block"
      >
        {children}
      </div>
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          className="z-50 px-3 py-2 text-xs border border-gray-50  bg-white text-gray-700 rounded-lg shadow-xl max-w-[336px]"
        >
          {content}
          <div
            ref={setArrowElement}
            className="absolute w-2 h-2 bg-white rotate-45 border-t border-gray-50"
            style={{
              top: placement === 'bottom' ? '-4px' : 'auto',
              bottom: placement === 'top' ? '-4px' : 'auto',
              right: placement === 'left' ? '-4px' : 'auto',
              left: placement === 'right' ? '-4px' : 'auto',
              transform:
                placement === 'top' || placement === 'bottom'
                  ? 'translateX(-50%)'
                  : 'translateY(-50%)',
              [placement === 'top' || placement === 'bottom' ? 'left' : 'top']:
                '50%',
            }}
          />
        </div>
      )}
    </>
  );
};

export default CustomTooltip;
