import { useQuery } from '@tanstack/react-query';
import { RequestParams } from '@/lib/types/common-types';
import {
  ActivitiesListResponse,
  useSmartOrdersData,
} from '../data/smart-orders/data';
import { buildActivity } from '../utils/activity-builder';

export const useActivity = (params: RequestParams) => {
  const { getActivitiesQueryFn } = useSmartOrdersData();
  const { data, isLoading, error } = useQuery({
    queryKey: ['activities', { ...params, offset: 0 }],
    queryFn: getActivitiesQueryFn,
    select: selectActivities,
    refetchInterval: 30000,
  });

  return { data, isLoading, error } as const;
};

const selectActivities = (data: ActivitiesListResponse | undefined) =>
  data?.activities.map((a) => buildActivity(a));
