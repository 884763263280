'use client';

import React from 'react';
import { Activity, ActivityTypes } from '@/lib/types/activity';
import { useActivityContent } from '@hooks/use-activity-content';
import clsx from 'clsx';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { useAmplitudeContext } from '@/lib/context/analytics-context';
import { NavArrowRight } from 'iconoir-react';
import { Tooltip } from 'react-tooltip';
import CustomTooltip from '../../common/tooltip';

const ActivityRow = ({ activity }: { activity: Activity }) => {
  const { ct } = useCatalogTranslation('admin_homepage');
  const { trackAmplitudeEvent } = useAmplitudeContext();

  const { activityContent } = useActivityContent(activity);

  return (
    <button
      onClick={() => {
        trackAmplitudeEvent?.('home-activity-feed-card-clicked', {
          activity_type: activity.type,
        });

        if (!activity.isCanceled) {
          activityContent?.onClick();
        }
      }}
      className="border-t border-gray-200 w-full cursor-pointer hover:bg-gray-50"
    >
      <div className="p-4 flex items-start justify-between gap-3">
        <div
          className={clsx(
            'flex items-center justify-center rounded-full h-5 w-5 min-w-5',
            {
              'bg-error-100':
                activity?.type === ActivityTypes.SMART_ORDER_FAILED ||
                activity?.type === ActivityTypes.SMART_ORDER_CANCELED,
              'bg-success-100':
                activity?.type === ActivityTypes.SMART_ORDER_SENT_TO_ERP ||
                activity?.type === ActivityTypes.SMART_ORDER_COMPLETED,
              'bg-secondary-100':
                activity?.type === ActivityTypes.SMART_ORDER_TO_COMPLETE,
              'bg-gray-100':
                activity?.type === ActivityTypes.SMART_ORDER_RECEIVED,
            },
          )}
        >
          {activityContent?.icon}
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm  text-left font-semibold text-gray-700">
            {activityContent?.title}
          </p>
          <div className="flex items-center gap-2">
            <p className="text-sm font-normal text-gray-700">
              {activityContent?.formattedDate}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {!activity.isCanceled && (
              <>
                <p className="text-sm font-semibold text-gray-700">
                  {ct('Homepage.activityFeedCta', 'Voir la commande')}
                </p>
                <NavArrowRight className="h-4 w-4" />
              </>
            )}
          </div>
          <div className="flex justify-end"></div>
        </div>
        <div className="flex self-end justify-self-end rounded-full h-full w-6">
          {activityContent?.agentName && (
            <CustomTooltip
              placement="bottom"
              content={activityContent?.agentName}
            >
              <span
                id={`initials-badge-${activity.id}`}
                className={clsx(
                  'text-xs flex items-center justify-center w-6 h-6 rounded-full',
                  activityContent?.agentColorClass,
                )}
              >
                {activityContent?.agentInitials}
              </span>
            </CustomTooltip>
          )}
        </div>
      </div>
    </button>
  );
};

export default ActivityRow;
