'use client';

import React from 'react';
import { useHomepageContext } from '@context/homepage-context';
import HomepageHeaderSkeleton from './homepage-header-skeleton';

const HomepageHeader = () => {
  const { titleLabels, isLoading } = useHomepageContext();

  if (isLoading) {
    return <HomepageHeaderSkeleton />;
  }

  if (!titleLabels) return null;
  const { title, subtitle } = titleLabels;

  return (
    <header className="p-8 pb-0 lg:max-w-[900px] xl:max-w-[1200px] 2xl:w-full 2xl:max-w-[1400px] mx-auto">
      <h1 className="h1 text-gray-900">{title}</h1>
      <p className="text-gray-700 text-sm">{subtitle}</p>
    </header>
  );
};

export default HomepageHeader;
