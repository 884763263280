import { QueryFunction, QueryKey } from '@tanstack/react-query';
import { ListApiResponse } from '../data';
import {
  DraftOrderFromApi,
  DraftOrderFromApiSchema,
  DraftOrderStatusEnum,
  DraftOrderTypeEnum,
} from '@/lib/types/smart-orders';
import { ShippingOption, ShippingOptionsSchema } from '@/lib/types/shipping';
import { useCatalogRequest } from '@hooks/use-catalog-request';
import { ActivityFromApi } from '@/lib/types/activity';
import { RequestParams } from '@/lib/types/common-types';

export type SmartOrdersListResponse = ListApiResponse & {
  draft_orders: DraftOrderFromApi[];
};

export type ActivitiesListResponse = ListApiResponse & {
  activities: ActivityFromApi[];
};

export type SmartOrdersCountResponse = {
  smart_orders_count: {
    failed: number;
    to_complete: number;
    processed: number;
  };
};

export const useSmartOrdersData = () => {
  const { catalogRequest } = useCatalogRequest();

  const getDraftOrdersQueryFn: QueryFunction<
    SmartOrdersListResponse,
    QueryKey
  > = async ({ queryKey }) => {
    const [_, type, expand] = queryKey;
    if (
      Object.values(DraftOrderTypeEnum).includes(type as DraftOrderTypeEnum)
    ) {
      return getDraftOrders(type as DraftOrderTypeEnum, expand as string);
    }
    console.error('Invalid type => ', type);
    return { draft_orders: [], count: 0, limit: 0, offset: 0 };
  };

  const getSmartOrdersQueryFn: QueryFunction<
    SmartOrdersListResponse,
    QueryKey
  > = async ({ queryKey }) => {
    const [_, status, assigneeIds] = queryKey;
    if (
      !status ||
      Object.values(DraftOrderStatusEnum).includes(
        status as DraftOrderStatusEnum,
      )
    ) {
      return getSmartOrders(
        status as DraftOrderStatusEnum | undefined,
        assigneeIds as (string | null)[],
      );
    }
    console.error('Invalid status => ', status);
    return { draft_orders: [], count: 0, limit: 0, offset: 0 };
  };

  const getDraftOrderQueryFn: QueryFunction<
    DraftOrderFromApi | null,
    ['smart_order', string]
  > = async ({ queryKey }) => {
    const [_, id] = queryKey;

    return getDraftOrderById(id);
  };

  const getDraftOrders = async (
    type: DraftOrderTypeEnum,
    expand: string,
  ): Promise<SmartOrdersListResponse> => {
    const path = '/draft-orders';
    const payload = { query: { type } as Record<string, string> };
    if (expand) {
      payload.query.expand = expand;
    }
    try {
      const response = await catalogRequest('GET', path, payload);
      const { body } = response;
      return {
        ...body,
      };
    } catch (error) {
      console.error('Fail to fetch smart orders', error);
      return { draft_orders: [], count: 0, limit: 0, offset: 0 };
    }
  };

  const getSmartOrders = async (
    status?: DraftOrderStatusEnum,
    assigneeIds?: (string | null)[],
  ): Promise<SmartOrdersListResponse> => {
    const path = '/draft-orders/smart-orders';
    const payload = {
      query: {
        limit: '500',
        expand: 'assignee',
      } as Record<string, string>,
    };
    if (status) {
      payload.query.status = status;
    }
    if (assigneeIds?.length) {
      payload.query['assignee_ids[]'] = assigneeIds
        .filter((a) => a !== null)
        .join(',');
    }
    if (assigneeIds?.some((a) => a === null)) {
      payload.query.include_non_assigned = 'true';
    }
    try {
      const response = await catalogRequest('GET', path, payload);
      const { body } = response;
      return {
        ...body,
      };
    } catch (error) {
      console.error('Fail to fetch smart orders', error);
      return { draft_orders: [], count: 0, limit: 0, offset: 0 };
    }
  };

  const getSmartOrdersCount = async (): Promise<SmartOrdersCountResponse> => {
    const path = '/draft-orders/smart-orders/count';
    try {
      const response = await catalogRequest('GET', path);
      const { body } = response;
      return {
        ...body,
      };
    } catch (error) {
      console.error('Fail to fetch smart orders count', error);
      return {
        smart_orders_count: {
          failed: 0,
          to_complete: 0,
          processed: 0,
        },
      };
    }
  };

  const getDraftOrderById = async (
    id: string,
  ): Promise<DraftOrderFromApi | null> => {
    const path = `/draft-orders/${id}`;
    try {
      const response = await catalogRequest('GET', path);
      return response.body.draft_order as DraftOrderFromApi;
    } catch (error) {
      console.error(`Fail to fetch smart order with id ${id}`, error);
      return null;
    }
  };

  const cancelSmartOrder = async ({ id }: { id: string }): Promise<void> => {
    const path = `/draft-orders/${id}`;
    await catalogRequest('DELETE', path);
  };

  const updateSmartOrder = async ({
    id,
    payload,
  }: {
    id: string;
    payload: {
      external_id?: string;
      customer_id?: string;
      company_id?: string;
      metadata?: Record<string, unknown>;
      type?: DraftOrderTypeEnum;
    };
  }) => {
    const path = `/draft-orders/${id}`;
    const response = await catalogRequest('POST', path, { body: payload });
    if (payload.company_id || payload.customer_id) {
      const updated = DraftOrderFromApiSchema.parse(response.body.draft_order);
      const shippingOptions = await getShippingOptions(
        updated.cart!.region_id!,
      );
      if (shippingOptions.length === 1) {
        const body = {
          shipping_methods: [{ option_id: shippingOptions[0].id }],
        };
        await catalogRequest('POST', path, { body });
      }
    }
  };

  const convertSmartOrder = async ({ id }: { id: string }): Promise<void> => {
    const path = `/draft-orders/${id}/convert-to-order`;
    await catalogRequest('POST', path);
  };

  const addLineItem = async ({
    id,
    payload,
  }: {
    id: string;
    payload: {
      variant_id: string;
      quantity: number;
      unit_price: number;
      rank: number;
    };
  }): Promise<DraftOrderFromApi | undefined> => {
    const path = `/draft-orders/${id}/line-items`;
    try {
      const response = await catalogRequest('POST', path, { body: payload });
      try {
        const parsedResponse = DraftOrderFromApiSchema.parse(
          response.body.draft_order,
        );

        const shippingOptions = await getShippingOptions(
          parsedResponse.cart!.region_id!,
        );
        if (shippingOptions.length === 1) {
          const body = {
            shipping_methods: [{ option_id: shippingOptions[0].id }],
          };
          const updatePath = `/draft-orders/${id}`;
          await catalogRequest('POST', updatePath, { body });
        }

        return parsedResponse;
      } catch (error) {
        throw { message: 'Validation Error: Invalid Draft Order', error };
      }
    } catch (error) {
      console.error('Error while adding a new item', error);
      return;
    }
  };

  const deleteLineItem = async ({
    draftId,
    itemId,
  }: {
    draftId: string;
    itemId: string;
  }): Promise<{ id: string; object: string; deleted: boolean } | undefined> => {
    const path = `/draft-orders/${draftId}/line-items/${itemId}`;
    try {
      const response = await catalogRequest('DELETE', path);

      const updated = DraftOrderFromApiSchema.parse(response.body.draft_order);
      const shippingOptions = await getShippingOptions(
        updated.cart!.region_id!,
      );
      if (shippingOptions.length === 1) {
        const body = {
          shipping_methods: [{ option_id: shippingOptions[0].id }],
        };
        const updatePath = `/draft-orders/${draftId}`;
        await catalogRequest('POST', updatePath, { body });
      }

      return response.body;
    } catch (error) {
      console.error('Error while deleting a new item', error);
      return;
    }
  };

  const editLineItem = async ({
    id,
    itemId,
    payload,
  }: {
    id: string;
    itemId: string;
    payload: {
      quantity?: number;
      unit_price?: number;
    };
  }): Promise<DraftOrderFromApi | undefined> => {
    const path = `/draft-orders/${id}/line-items/${itemId}`;
    try {
      const response = await catalogRequest('POST', path, { body: payload });
      try {
        const parsedResponse = DraftOrderFromApiSchema.parse(
          response.body.draft_order,
        );

        const shippingOptions = await getShippingOptions(
          parsedResponse.cart!.region_id!,
        );
        if (shippingOptions.length === 1) {
          const body = {
            shipping_methods: [{ option_id: shippingOptions[0].id }],
          };
          const updatePath = `/draft-orders/${id}`;
          await catalogRequest('POST', updatePath, { body });
        }

        return parsedResponse;
      } catch (error) {
        throw { message: 'Validation Error: Invalid Draft Order', error };
      }
    } catch (error) {
      console.error(`Error while editing item ${itemId}`, error);
      return;
    }
  };

  const getShippingOptions = async (
    regionId: string,
  ): Promise<ShippingOption[]> => {
    const path = '/shipping-options';
    const response = await catalogRequest('GET', path, {
      query: { region_id: regionId },
    });
    const parsedResponse = ShippingOptionsSchema.parse(
      response.body.shipping_options,
    );
    return parsedResponse;
  };

  const getActivities = async (
    params: RequestParams = {},
  ): Promise<ActivitiesListResponse> => {
    const path = '/activities';
    const response = await catalogRequest('GET', path, {
      query: { ...params },
    });
    return response.body;
  };

  const getActivitiesQueryFn: QueryFunction<
    ActivitiesListResponse,
    QueryKey
  > = async ({ queryKey }) => {
    const params = queryKey[1] as RequestParams | undefined;
    return getActivities(params);
  };

  return {
    getShippingOptions,
    getDraftOrdersQueryFn,
    getSmartOrdersQueryFn,
    getDraftOrderQueryFn,
    cancelSmartOrder,
    updateSmartOrder,
    convertSmartOrder,
    addLineItem,
    deleteLineItem,
    editLineItem,
    getSmartOrdersCount,
    getActivitiesQueryFn,
  };
};
