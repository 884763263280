import { Address, AddressFromApi } from '@/lib/types/address';
import { convertKeysToCamelCase } from './case-convertor';

export const buildAddress = (
  address: AddressFromApi,
  locale: Locale,
): Address => {
  const createdAt = new Date(address.created_at!);
  const updatedAt = address.updated_at
    ? new Date(address.updated_at)
    : undefined;
  delete address.created_at;
  delete address.updated_at;
  if (typeof address.country_code === 'object') {
    return {
      ...convertKeysToCamelCase(address),
      isDefault:
        address.metadata && address.metadata.is_default
          ? Boolean(address.metadata.is_default)
          : false,
      createdAt,
      updatedAt,
    };
  }

  const code = locale.code as string;
  const countryName = new Intl.DisplayNames([code], {
    type: 'region',
  });

  return {
    ...convertKeysToCamelCase(address),
    country: countryName.of(address.country_code!.toUpperCase()),
    isDefault:
      address.metadata && address.metadata.is_default
        ? Boolean(address.metadata.is_default)
        : false,
    createdAt,
    updatedAt,
  };
};
