import { differenceInDays } from 'date-fns';
import { Company, CompanyFromApi } from '../types/company';
import { buildAddress } from './address-builder';
import { convertKeysToCamelCase } from './case-convertor';
import { Catalog, CatalogFromApi } from '../types/catalog';
import { buildUser } from './user-builder';

export const buildCompany = (
  company: CompanyFromApi,
  newCustomerDelay: number,
  locale: Locale,
): Company => {
  const today = new Date();
  const companyCreatedAt = new Date(company.created_at);
  const diffDays = differenceInDays(today, companyCreatedAt);
  const shippingAddresses =
    company && company.shipping_addresses
      ? company.shipping_addresses.map((a) => buildAddress(a, locale))
      : [];
  shippingAddresses?.sort((a, b) => {
    if (a.isDefault === b.isDefault) {
      return (
        new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime()
      );
    }
    return a.isDefault ? -1 : 1;
  });
  return {
    ...convertKeysToCamelCase(company, 3),
    agent: buildUser(company.agent!),
    franco: company.franco ? company.franco / 100 : null,
    minOrderAmount: getMinorderAmount(company.metadata),
    createdAt: new Date(company.created_at),
    updatedAt: company.updated_at
      ? new Date(company.updated_at)
      : new Date(company.created_at),
    billingAddress:
      company && company.billing_address
        ? buildAddress(company.billing_address, locale)
        : undefined,
    shippingAddresses,
    isNewCustomer: diffDays !== null ? diffDays <= newCustomerDelay : false,
    customerGroup: buildCustomerGroup(company.customer_group),
  };
};

const buildCustomerGroup = (cg: CompanyFromApi['customer_group']) => {
  if (!cg) return undefined;
  return {
    id: cg.id,
    name: cg.name,
    priceLists: cg.price_lists.map((pl) => ({
      id: pl.id,
      name: pl.name,
      description: pl.description,
      type: pl.type,
      status: pl.status,
      catalog: convertKeysToCamelCase<CatalogFromApi, Catalog>(pl.catalog, 1),
    })),
  };
};

const getMinorderAmount = (
  metadata: CompanyFromApi['metadata'],
): number | null => {
  return typeof metadata?.min_order_amount === 'number'
    ? metadata.min_order_amount / 100
    : null;
};
