import { useOnboarding } from '@/lib/hooks/use-onboarding';
import ProgressBar from '../../common/progress-bar';
import { useCatalogTranslation } from '@/lib/hooks/use-catalog-translations';

export function OnboardingProgress() {
  const { onboardingProgression, onboardingThreshold } = useOnboarding();
  const { ct } = useCatalogTranslation('admin_common');

  if (onboardingProgression >= onboardingThreshold) {
    return null;
  }

  return (
    <div className="onboarding-progress-card">
      <span className="text-gray-900 text-sm font-semibold">
        {ct(
          'CommonSidebar.onboardingOrderToRaise',
          'Traiter {{count}} commandes',
          {
            count: onboardingThreshold,
          },
        )}
      </span>
      <ProgressBar progression={onboardingProgression} />
      <div className="text-gray-700 text-sm">{`${onboardingProgression}%`}</div>
    </div>
  );
}
