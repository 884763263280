'useClient';
import React from 'react';

type ButtonProps = {
  color: 'primary' | 'alert' | 'success' | 'secondary' | 'tertiary';
  size: 'lg' | 'md' | 'sm' | 'xs';
  children: React.ReactNode;
  testId?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  color = 'primary',
  size = 'md',
  children,
  testId,
  ...props
}: ButtonProps) => {
  const getSizeClasses = () => {
    switch (size) {
      case 'lg':
        return 'btn-lg';
      case 'md':
        return 'btn-md';
      case 'sm':
        return 'btn-sm';
      case 'xs':
        return 'btn-xs';
    }
  };

  const getColorClasses = () => {
    switch (color) {
      case 'primary':
        return size === 'xs' ? 'btn-text-primary' : 'btn-primary';
      case 'alert':
        return size === 'xs' ? 'btn-text-alert' : 'btn-alert';
      case 'success':
        return size === 'xs' ? 'btn-text-success' : 'btn-success';
      case 'secondary':
        return size === 'xs' ? 'btn-text-secondary' : 'btn-secondary';
      case 'tertiary':
        return size === 'xs' ? 'btn-text-tertiary' : 'btn-tertiary';
    }
  };

  const classes = `whitespace-nowrap btn ${getSizeClasses()} ${getColorClasses()}`;

  return (
    <button className={classes} data-testid={testId} {...props}>
      {children}
    </button>
  );
};

export default Button;
