'use client';

import clsx from 'clsx';
import React from 'react';

type BadgeProps = {
  type?: 'red' | 'orange' | 'green' | 'blue' | 'purple' | 'gray' | 'white';
  size?: 'sm' | 'md' | 'lg';
  dashed?: boolean;
  children?: React.ReactNode;
};
const Badge = ({
  type = 'orange',
  size = 'sm',
  dashed = false,
  children,
  ...props
}: BadgeProps): JSX.Element => {
  const getClasses = () => {
    const classes = [];
    switch (type) {
      case 'red':
        classes.push('bg-error-100 text-error-500  border-error-500');
        break;
      case 'orange':
        classes.push('bg-orange-100 text-orange-500  border-orange-500');
        break;
      case 'green':
        classes.push('bg-success-100 text-success-500  border-success-500');
        break;
      case 'blue':
        classes.push(
          'bg-secondary-100 text-secondary-500  border-secondary-500',
        );
        break;
      case 'purple':
        classes.push('bg-purple-light text-purple-dark  border-purple-dark');
        break;
      case 'gray':
        classes.push('bg-gray-100 text-gray-600  border-gray-600');
        break;
      case 'white':
        classes.push('bg-white text-gray-600 border border-gray-200');
        break;
    }

    switch (size) {
      case 'sm':
        classes.push('text-xs px-1');
        break;
      case 'md':
        classes.push('text-sm px-2 py-1');
        break;
      case 'lg':
        classes.push('text-sm px-3 py-2');
        break;
    }
    if (dashed) {
      classes.push('border-dashed border');
    }
    return classes;
  };

  return (
    <div
      data-testid="catalog-badge"
      {...props}
      className={clsx(getClasses(), [
        'inline-flex items-center justify-center gap-1 rounded-2xl px-3 py-1 text-sm',
      ])}
    >
      {children}
    </div>
  );
};

export default Badge;
