'use client';

import { createContext, useContext, useMemo } from 'react';
import { useCatalogTranslation } from '../hooks/use-catalog-translations';
import { useAccount } from './account-context';
import { useSmartOrderCounts } from './smart-order-counts-context';
import { useOnboarding } from '../hooks/use-onboarding';
import { useTranslation } from 'react-i18next';

interface IHomepageContext {
  failedCount: number;
  toCompleteCount: number;
  sentCount: number;
  titleLabels: {
    title: string;
    subtitle: string;
  } | null;
  onboardingThreshold: number;
  onboardingProgression: number;
  isLoading: boolean;
}

const initialData: IHomepageContext = {
  failedCount: 0,
  toCompleteCount: 0,
  sentCount: 0,
  titleLabels: {
    title: '',
    subtitle: '',
  },
  onboardingThreshold: 0,
  onboardingProgression: 0,
  isLoading: true,
};

export const HomepageContext = createContext<IHomepageContext>(initialData);

export const HomepageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { ct } = useCatalogTranslation('admin_homepage');
  const { i18n } = useTranslation();
  const { ...loggedUser } = useAccount();
  const {
    failedCount,
    toCompleteCount,
    sentCount,
    isLoading: isSmartOrderCountsLoading,
  } = useSmartOrderCounts();
  const { onboardingProgression, onboardingThreshold } = useOnboarding();

  const titleLabels = useMemo(() => {
    if (!loggedUser) return null;
    if (toCompleteCount > 0) {
      return {
        title: ct(
          'Homepage.titleWithOrdersToComplete',
          'Bonjour {{ user }}, on attaque ?',
          { user: loggedUser.firstName },
        ),
        subtitle: ct(
          'Homepage.subTitleWithOrdersToComplete',
          'Arthur est là pour vous aider à compléter les {{count}} commandes en attente. Vous avez tout sous contrôle !',
          { count: toCompleteCount },
        ),
      };
    }
    return {
      title: ct(
        'Homepage.titleNoWaitingOrders',
        'Bonjour {{ user }}, on attaque ?',
        { user: loggedUser.firstName },
      ),
      subtitle: ct(
        'Homepage.subTitleNoWaitingOrders',
        'Arthur est là pour vous aider. Vous avez tout sous contrôle !',
      ),
    };
  }, [loggedUser, toCompleteCount, i18n.language]);

  return (
    <HomepageContext.Provider
      value={{
        failedCount,
        toCompleteCount,
        sentCount,
        titleLabels,
        onboardingProgression,
        onboardingThreshold,
        isLoading: isSmartOrderCountsLoading,
      }}
    >
      {children}
    </HomepageContext.Provider>
  );
};

export const useHomepageContext = () => {
  const context = useContext(HomepageContext);
  if (context === undefined) {
    throw new Error(
      'useHomepageContext must be used within a HomepageProvider',
    );
  }
  return context;
};
