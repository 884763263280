import { z } from 'zod';
import { Contact, Customer, UserApiSchema, Agent } from './user';
import { Address, AddressApiSchema } from './address';
import { Catalog, CatalogApiSchema } from './catalog';

export const BaseCompanyApiSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
  name: z.string(),
  code: z.string(),
  logo: z.nullable(z.string()).optional(),
  payment_terms: z.nullable(z.string()).optional(),
  registration_number: z.nullable(z.string()).optional(),
  vat_number: z.nullable(z.string()).optional(),
  agent_id: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.string(), z.unknown())).optional(),
  parent_company_id: z.nullable(z.string()).optional(),
  payment_currency_code: z.nullable(z.string()).optional(),
  franco: z.nullable(z.number()).optional(),
  seller_company_id: z.nullable(z.string()).optional(),
  turnover: z.nullable(z.number()).optional(),
});

export const CustomerApiSchema = UserApiSchema.extend({
  company_id: z.nullable(z.string()).optional(),
  phone: z.nullable(z.string()).optional(),
  has_account: z.boolean().optional(),
  password: z.nullable(z.string()).optional(),
  company: BaseCompanyApiSchema.optional(),
});

export const CompanyApiSchema = BaseCompanyApiSchema.extend({
  agent: z.nullable(UserApiSchema).optional(),
  contacts: z.nullable(z.array(UserApiSchema)).optional(),
  customers: z.nullable(z.array(CustomerApiSchema)).optional(),
  billing_address: z.nullable(AddressApiSchema).optional(),
  shipping_addresses: z.nullable(z.array(AddressApiSchema)).optional(),
  customer_group: z
    .object({
      id: z.string(),
      name: z.string(),
      price_lists: z.array(
        z.object({
          catalog: CatalogApiSchema,
          description: z.string().optional(),
          id: z.string(),
          name: z.string(),
          status: z.string().optional(),
          type: z.string().optional(),
        }),
      ),
    })
    .optional(),
});

export type CompanyFromApi = z.infer<typeof CompanyApiSchema>;

export type PriceList = {
  id: string;
  name: string;
  description?: string;
  type?: string;
  status?: string;
  catalog: Catalog;
};

export type CustomerGroup = {
  id: string;
  name: string;
  priceLists: PriceList[];
};

export type Company = {
  id: string;
  name: string;
  code: string;
  createdAt: Date;
  updatedAt: Date;
  logo?: string;
  paymentTerms?: string;
  registrationNumber?: string;
  vatNumber?: string;
  agentId?: string;
  metadata?: Record<string, any>;
  parentCompanyId?: string;
  paymentCurrencyCode?: string;
  franco?: number | null;
  minOrderAmount?: number | null;
  sellerCompanyId?: string;
  turnover?: number;
  agent?: Agent;
  contacts?: Contact[];
  customers?: Customer[];
  billingAddress?: Address;
  shippingAddresses?: Address[];
  isNewCustomer: boolean;
  customerGroup?: CustomerGroup;
};

export const PostCompanyPayloadSchema = z.object({
  name: z.string().optional(),
  agent: UserApiSchema.partial().optional(),
  payment_terms: z.string().optional(),
  registration_number: z.nullable(z.string()).optional(),
  vat_number: z.nullable(z.string()).optional(),
  contacts: z.nullable(z.array(UserApiSchema.partial())).optional(),
  billing_address: AddressApiSchema.partial().optional(),
  shipping_addresses: z.array(AddressApiSchema.partial()).optional(),
  customers: z.array(CustomerApiSchema.partial()).optional(),
  franco: z.nullable(z.number()).optional(),
  price_list_ids: z.array(z.string()).optional(),
  metadata: z.record(z.string(), z.unknown()).optional(),
});

export type PostCompanyPayload = z.infer<typeof PostCompanyPayloadSchema>;

export type UpdateCompanyPayload = PostCompanyPayload & { id: string };
