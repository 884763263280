'use client';

import { useCallback } from 'react';
import {
  UseMutationResult, // keep this import for the useOrderMutations function
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  SmartOrdersListResponse,
  useSmartOrdersData,
} from '../data/smart-orders/data';
import { buildSmartOrder } from '../utils/smart-order-builder';
import { DraftOrderFromApi, SmartOrder } from '../types/smart-orders';
import { useLocalLanguage } from './use-locale-language';
import { useAccount } from '../context/account-context';
import { useSettings } from './use-settings';

export const useSmartOrders = (assigneeIds?: (string | null)[]) => {
  const { getSmartOrdersQueryFn } = useSmartOrdersData();
  const { isDebugModeActive } = useAccount();
  const { newCustomerDelay, smartOrderValidationRules } = useSettings();
  const locale = useLocalLanguage();

  const buildSmartOrders = useCallback(
    (data: SmartOrdersListResponse) =>
      data.draft_orders
        .map((draftOrder) =>
          buildSmartOrder(
            draftOrder,
            locale,
            newCustomerDelay ?? 0,
            smartOrderValidationRules,
            isDebugModeActive,
          ),
        )
        .filter(Boolean) as SmartOrder[],
    [locale, newCustomerDelay, smartOrderValidationRules, isDebugModeActive],
  );

  const queryKey: QueryKey = ['smart_orders', undefined, assigneeIds] as const;
  const { data: smartOrders, isLoading } = useQuery({
    queryKey,
    queryFn: getSmartOrdersQueryFn,
    refetchInterval: 30000,
    staleTime: 25000,
    select: buildSmartOrders,
  });
  return { smartOrders, isLoading } as const;
};

export const useSmartOrder = (id: string | null) => {
  const { getDraftOrderQueryFn } = useSmartOrdersData();
  const { isDebugModeActive } = useAccount();
  const { newCustomerDelay, smartOrderValidationRules } = useSettings();
  const locale = useLocalLanguage();

  const buildSo = useCallback(
    (data: DraftOrderFromApi | null) =>
      data
        ? buildSmartOrder(
            data,
            locale,
            newCustomerDelay ?? 0,
            smartOrderValidationRules,
            isDebugModeActive,
          )
        : undefined,
    [locale, newCustomerDelay, smartOrderValidationRules, isDebugModeActive],
  );

  const queryKey: ['smart_order', string] = ['smart_order', id!];
  const { data: smartOrder, isLoading } = useQuery({
    queryKey,
    queryFn: getDraftOrderQueryFn,
    enabled: !!id,
    select: buildSo,
  });
  return { smartOrder, isLoading };
};

export const useSmartOrderMutations = () => {
  const {
    cancelSmartOrder,
    updateSmartOrder,
    convertSmartOrder,
    addLineItem,
    deleteLineItem,
    editLineItem,
  } = useSmartOrdersData();
  const queryClient = useQueryClient();

  const cancelSmartOrderMutation = useMutation({
    mutationFn: cancelSmartOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smart_orders'] });
    },
  });

  const updateDraftOrderMutation = useMutation({
    mutationFn: updateSmartOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smart_orders'] });
      queryClient.invalidateQueries({ queryKey: ['smart_order'] });
    },
  });

  const convertDraftToOrderMutation = useMutation({
    mutationFn: convertSmartOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smart_orders'] });
      queryClient.invalidateQueries({ queryKey: ['smart_order'] });
    },
  });

  const addSmartOrderLineItem = useMutation({
    mutationFn: addLineItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smart_order'] });
    },
  });

  const deleteSmartOrderLineItem = useMutation({
    mutationFn: deleteLineItem,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smart_order'] });
    },
  });

  const editSmartOrderLineItem = useMutation({
    mutationFn: editLineItem,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smart_order'] });
    },
  });

  return {
    cancelSmartOrderMutation,
    updateDraftOrderMutation,
    convertDraftToOrderMutation,
    addSmartOrderLineItem,
    deleteSmartOrderLineItem,
    editSmartOrderLineItem,
  } as const;
};

export const useSmartOrdersCount = () => {
  const { getSmartOrdersCount } = useSmartOrdersData();
  const { data, isLoading } = useQuery({
    queryKey: ['smart_orders_count'],
    queryFn: getSmartOrdersCount,
    refetchInterval: 30000,
  });

  const smartOrdersCount = data?.smart_orders_count;

  if (!smartOrdersCount) {
    return { failed: 0, to_complete: 0, processed: 0, isLoading };
  }

  return { ...smartOrdersCount, isLoading };
};
