import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/app/i18n.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/lib/components/common/datadog-init.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/lib/components/layout/left-sidebar/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/lib/components/layout/on-behalf-banner/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/lib/context/left-sidebar-context.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/lib/context/providers.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/lib/context/tenant-context.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/avatar/avatar.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/calendar/calendar.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/checkbox/checkbox.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/code-block/code-block.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/command-bar/command-bar.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/command/command.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/copy/copy.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/currency-input/currency-input.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/date-picker/date-picker.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/drawer/drawer.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/dropdown-menu/dropdown-menu.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/focus-modal/focus-modal.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/input/input.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/label/label.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/progress-accordion/progress-accordion.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/progress-tabs/progress-tabs.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/prompt/prompt.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/radio-group/radio-group.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/select/select.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/switch/switch.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/tabs/tabs.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/toast/toast.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/toaster/toaster.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/components/tooltip/tooltip.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/hooks/use-prompt/use-prompt.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/hooks/use-toast/use-toast.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/@medusajs/ui/dist/esm/hooks/use-toggle-state/use-toggle-state.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/next/font/google/target.css?{\"path\":\"lib/components/common/fonts/font.ts\",\"import\":\"Manrope\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/seller-front/seller-front/node_modules/next/font/local/target.css?{\"path\":\"lib/components/common/fonts/font.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./PolySans-Median.ttf\",\"display\":\"swap\",\"variable\":\"--font-polySans\"}],\"variableName\":\"polySans\"}")