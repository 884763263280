import { z } from 'zod';

export const AddressApiSchema = z.object({
  id: z.string(),
  customer_id: z.nullable(z.string()).optional(),
  first_name: z.nullable(z.string()).optional(),
  last_name: z.nullable(z.string()).optional(),
  address_1: z.nullable(z.string()).optional(),
  address_2: z.nullable(z.string()).optional(),
  city: z.nullable(z.string()).optional(),
  country_code: z.nullable(z.string()).optional(),
  province: z.nullable(z.string()).optional(),
  postal_code: z.nullable(z.string()).optional(),
  phone: z.nullable(z.string()).optional(),
  is_manual: z.boolean().optional(),
  company_shipping_id: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.string(), z.unknown())).optional(),
  created_at: z.string().optional(),
  updated_at: z.nullable(z.string()).optional(),
});

export type AddressFromApi = z.infer<typeof AddressApiSchema>;

export type Address = {
  id: string;
  customerId?: string;
  firstName?: string;
  lastName?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  countryCode?: string;
  country?: string;
  province?: string;
  postalCode?: string;
  phone?: string;
  isManual?: boolean;
  companyShippingId?: string;
  isDefault?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};
