import { z } from 'zod';
import { RequestParams } from './common-types';

const BaseApiSchema = z.object({
  id: z.string(),
  created_at: z.nullable(z.string()),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

export const ImageApiSchema = z.object({
  id: z.nullable(z.string()).optional(),
  url: z.nullable(z.string().url()).optional(),
});

export const ProductOptionApiSchema = BaseApiSchema.extend({
  value: z.nullable(z.string()).optional(),
  title: z.nullable(z.string()).optional(),
  option_id: z.nullable(z.string()).optional(),
  variant_id: z.nullable(z.string()).optional(),
  option: z
    .nullable(
      z.object({
        title: z.nullable(z.string()).optional(),
        metadata: z.nullable(z.record(z.unknown())).optional(),
      }),
    )
    .optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
});

export const VariantApiSchema = BaseApiSchema.extend({
  title: z.string(),
  product_id: z.string().optional(),
  sku: z.string(),
  barcode: z.nullable(z.string()).optional(),
  ean: z.nullable(z.string()).optional(),
  inventory_quantity: z.nullable(z.number()),
  material: z.nullable(z.string()).optional(),
  weight: z.nullable(z.number().or(z.string())).optional(),
  length: z.nullable(z.number().or(z.string())).optional(),
  height: z.nullable(z.number().or(z.string())).optional(),
  width: z.nullable(z.number().or(z.string())).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  thumbnail: z.nullable(z.string()).optional(),
  prices: z
    .nullable(
      z.array(
        z.object({
          currency_code: z.nullable(z.string()).optional(),
          amount: z.nullable(z.number()).optional(),
          price_list_id: z.nullable(z.string()).optional(),
        }),
      ),
    )
    .optional(),
  images: z
    .nullable(z.union([ImageApiSchema, z.array(ImageApiSchema)]))
    .optional(),
  options: z.nullable(z.array(ProductOptionApiSchema)).optional(),
  original_price: z.nullable(z.number()).optional(),
  calculated_price: z.nullable(z.number()).optional(),
});

const CategoryApiSchema = z.object({
  id: z.string().optional(),
  name: z.nullable(z.string()).optional(),
  thumbnail: z.nullable(z.string()).optional(),
  handle: z.string().optional(),
  metadata: z.nullable(z.object({})).optional(),
});

export const ProductApiSchema = BaseApiSchema.extend({
  title: z.string().optional(),
  subtitle: z.nullable(z.string()).optional(),
  description: z.nullable(z.string()).optional(),
  handle: z.nullable(z.string()).optional(),
  status: z
    .union([
      z.literal('draft'),
      z.literal('proposed'),
      z.literal('published'),
      z.literal('rejected'),
    ])
    .optional(),
  thumbnail: z.nullable(z.string()).optional(),
  weight: z.nullable(z.number()).optional(),
  width: z.nullable(z.number()).optional(),
  height: z.nullable(z.number()).optional(),
  length: z.nullable(z.number()).optional(),
  material: z.nullable(z.string()).optional(),
  categories: z.nullable(z.array(CategoryApiSchema)).optional(),
  external_id: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  variants: z.nullable(z.array(VariantApiSchema)).optional(),
  options: z.nullable(z.array(ProductOptionApiSchema)).optional(),
  images: z.nullable(z.array(ImageApiSchema)).optional(),
});

export type ProductFromApi = z.infer<typeof ProductApiSchema>;
export type ProductOptionFromApi = z.infer<typeof ProductOptionApiSchema>;
export type VariantFromApi = z.infer<typeof VariantApiSchema>;
export type ImageFromApi = z.infer<typeof ImageApiSchema>;

export type ProductStatus = 'draft' | 'proposed' | 'published' | 'rejected';

export type Product = {
  id: string;
  category: string;
  categoryId: string;
  title: string;
  thumbnail: string;
  description: string;
  hasRangePrice: boolean;
  images: string[];
  price: number;
  options: ProductOption[];
  variants: Variant[];
  variantCount: number;
  quantity: number;
  status: string;
  metadata: Record<string, unknown>;
};

export type ProductOption = {
  id: string;
  title: string;
};

export type VariantOption = {
  id: string;
  optionId: string;
  title: string;
  value: string;
};

export type Variant = {
  id: string;
  productId: string;
  title: string;
  moq: number;
  pcb: number;
  img: string;
  ref: string;
  price: number;
  quantity: number;
  options: ProductOption[];
  metadata: Record<string, unknown>;
  description: string;
  isPreorder?: boolean;
};

export type VariantRow = {
  id: string;
  ref: string;
  title: string;
  moq: number | null;
  pcb: number | null;
  thumbnail?: string | null;
  inventoryQuantity: number;
  options: VariantOption[];
  isPreorder: boolean;
  isOnDemand: boolean;
  displayUnit: string | null;
  storageUnit: string | null;
  decimal: boolean;
};

export type AddVariantLine = Omit<
  VariantRow,
  'options' | 'isPreorder' | 'isOnDemand'
> & { price: number };

export type ProductRow = {
  id: string;
  title?: string;
  thumbnail?: string | null;
  variants?: VariantRow[];
  options?: ProductOption[];
};

export type ProductQueryParams = RequestParams & {
  id?: string;
  status?: string; // to define
  category_id?: string;
  title?: string;
  handle?: string;
  description?: string;
};

export type VariantQueryParams = ProductQueryParams & {
  customer_id?: string;
};
