import React from 'react';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { useHomepageContext } from '@context/homepage-context';
import { useAmplitudeContext } from '@/lib/context/analytics-context';
import {
  Archive,
  MultiplePagesMinus,
  NavArrowRight,
  Check,
  Xmark,
} from 'iconoir-react';
import { useRouter } from 'next/navigation';
import Badge from '../../common/badge';
import NextStepBlockSkeleton from './next-step-block-skeleton';

const NextStepBlock = () => {
  const { ct } = useCatalogTranslation(['admin_homepage', 'admin_orders']);
  const { failedCount, toCompleteCount, isLoading } = useHomepageContext();
  const router = useRouter();
  const { trackAmplitudeEvent } = useAmplitudeContext();
  const toCompleteLabel = ct(
    'Homepage.nextStepToComplete',
    'Voir les {{count}} commandes à traiter',
    { count: toCompleteCount, ns: 'admin_homepage' },
  );

  const toSeizeLabel = ct(
    'Homepage.nextStepToSeize',
    'Voir les {{count}} commandes à saisir',
    { count: failedCount, ns: 'admin_homepage' },
  );

  const noActionsTitle = ct(
    'Homepage.nextStepNoActionsTitle',
    'Tout est sous contrôle.',
    { ns: 'admin_homepage' },
  );

  const noActionsDescription = ct(
    'Homepage.nextStepNoActionsDescription',
    'Aucune tâche en attente. Votre efficacité paie ! Revenez plus tard pour voir si de nouvelles actions apparaissent.',
    { ns: 'admin_homepage' },
  );

  if (isLoading) {
    return <NextStepBlockSkeleton />;
  }

  return (
    <div className="homepage-card p-0">
      <div>
        <h2 className="h2 p-4">
          {ct('Homepage.nextStepTitle', 'Prochaines étapes')}
        </h2>
        {toCompleteCount > 0 && (
          <button
            className="p-4 flex items-center justify-between border-t border-gray-200 hover:bg-gray-50 cursor-pointer w-full"
            onClick={() => {
              router.push('smartorder');
              trackAmplitudeEvent?.('home-order-to-complete-clicked');
            }}
          >
            <div className="flex items-center gap-3">
              <Archive className="h-4 w-4 text-gray-900" />
              <span className="text-sm font-medium text-gray-900">
                {toCompleteLabel}
              </span>
              <Badge type="blue" size="sm">
                <Check />
                {ct('SmartOrders.columnToVerify', 'Commandes à compléter', {
                  ns: 'admin_orders',
                })}
              </Badge>
            </div>
            <span>
              <NavArrowRight className="h-4 w-4 text-gray-700" />
            </span>
          </button>
        )}
        {failedCount > 0 && (
          <button
            className="p-4 flex items-center justify-between border-t border-gray-200 hover:bg-gray-50 cursor-pointer w-full"
            onClick={() => {
              router.push('smartorder');
              trackAmplitudeEvent?.('home-order-failed-clicked');
            }}
          >
            <div className="flex items-center gap-3">
              <MultiplePagesMinus className="h-4 w-4 text-gray-900" />
              <span className="text-sm font-medium text-gray-900">
                {toSeizeLabel}
              </span>
              <Badge type="red" size="sm">
                <Xmark className="h-3 w-3" />
                {ct('SmartOrders.columnFail', 'Échec de traitement', {
                  ns: 'admin_orders',
                })}
              </Badge>
            </div>
            <span>
              <NavArrowRight className="h-4 w-4 text-gray-700" />
            </span>
          </button>
        )}
        {toCompleteCount === 0 && failedCount === 0 && (
          <div className="flex flex-col items-center text-center gap-3 w-full border-t border-gray-200 p-4">
            <div className="flex items-center justify-center h-8 w-8 bg-gray-50 rounded-full border border-gray-200">
              <Check className="h-4 w-4 text-gray-900" />
            </div>
            <div className="text-sm font-semibold text-gray-900">
              {noActionsTitle}
            </div>
            <div className="text-xs font-medium text-gray-700 max-w-[338px]">
              {noActionsDescription}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NextStepBlock;
