'use client';

import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import ReactCountryFlag from 'react-country-flag';
import { NavArrowDown } from 'iconoir-react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '@utils/constant';
import { languageMapper } from '@utils/language-mapper';
import { Language } from '@/lib/types/common-types';
import { useQueryClient } from '@tanstack/react-query';

const LanguageDropdown = ({ full = true }: { full: boolean }) => {
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    LANGUAGES[0],
  );
  const flagStyles = {
    fontSize: '1.5em',
    lineHeight: '1.5em',
    borderRadius: '6px',
  };

  useEffect(() => {
    if (localStorage?.getItem('i18nextLng')) {
      let localLanguage = localStorage.getItem('i18nextLng');
      const language = LANGUAGES.find((l) => {
        if (localLanguage?.includes('-')) {
          localLanguage = localLanguage.split('-')[0];
        }
        return l.shortLabel
          .toLowerCase()
          .includes(localLanguage!.toLowerCase());
      });
      if (language) {
        langSelected(language);
      }
    }
  }, []);

  const langSelected = (language: Language) => {
    setSelectedLanguage(language);
    const i18nLanguage = languageMapper(language.countryCode);
    if (i18n.language === i18nLanguage) return;
    console.log({ i18nLanguage, i18n, language });
    i18n.changeLanguage(i18nLanguage);
    queryClient.invalidateQueries();
  };

  return (
    <Menu
      data-testid="language-dropdown"
      as="div"
      className="relative inline-block text-left"
    >
      {({ open }) => (
        <>
          <div>
            <MenuButton
              className={clsx(
                'inline-flex w-full items-center justify-center gap-2 rounded-md py-2 pr-3 text-sm font-semibold text-gray-600',
                open ? 'bg-gray-50' : 'bg-white',
              )}
            >
              <ReactCountryFlag
                countryCode={selectedLanguage.countryCode}
                style={flagStyles}
                svg
                aria-label={selectedLanguage.shortLabel}
              />
              {full && (
                <>
                  <span>{selectedLanguage.shortLabel}</span>
                  <NavArrowDown className="h-4 w-4 font-semibold text-gray-600" />
                </>
              )}
            </MenuButton>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute left-full bottom-0 z-30 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {LANGUAGES.map((language) => (
                  <MenuItem key={language.label}>
                    <button
                      className="group flex w-full items-center gap-2 px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      onClick={() => langSelected(language)}
                    >
                      <ReactCountryFlag
                        data-testid="country-flag"
                        countryCode={language.countryCode}
                        style={flagStyles}
                        svg
                        aria-label={language.shortLabel}
                      />
                      <span>{language.shortLabel}</span>
                    </button>
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default LanguageDropdown;
