'use client';

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigation } from '@/lib/hooks/use-navigation';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLeftSidebarContext } from '@context/left-sidebar-context';

function Navigation() {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const { isExpanded } = useLeftSidebarContext();
  const { navigation } = useNavigation();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname) {
      const currentTab = navigation.find((item) => item.link === pathname);
      if (currentTab) {
        setSelectedItem(currentTab.anchor);
      }
    }
  }, [pathname]);
  return (
    <nav data-testid="main-navbar">
      <ul className="flex flex-col gap-2">
        {navigation.map((item) => (
          <li key={item.link} className="h-9">
            <Link
              href={item.link}
              onClick={() => setSelectedItem(item.anchor)}
              className={clsx(
                'flex items-center gap-2 font-semibold text-gray-700 w-full h-full rounded-lg px-2',
                'transition-colors',
                selectedItem === item.anchor
                  ? 'bg-gray-200 text-gray-900 hover:bg-gray-200'
                  : 'hover:bg-gray-50',
              )}
            >
              {item.icon}
              <span
                className={clsx('transition-opacity', !isExpanded && 'hidden')}
              >
                {item.label}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Navigation;
