'use client';

import { useCatalogTranslation } from '@hooks/use-catalog-translations';

const keys = ['a', 'b', 'c'];

const ActivityFeedSkeleton = () => {
  const { ct } = useCatalogTranslation('admin_homepage');

  return (
    <div className="homepage-card p-0">
      <h2 className="h2 p-4 pb-0">
        {ct('Homepage.activityFeedTitle', 'Activités')}
      </h2>
      <div className="relative border-b border-gray-200">
        <div className="animate-pulse">
          {[...Array(3)].map((_, index) => (
            <div
              key={keys[index]}
              className="p-4 border-b border-gray-100 last:border-0"
            >
              <div className="flex items-start gap-3">
                <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
                <div className="flex-1">
                  <div className="h-4 w-3/4 bg-gray-200 rounded mb-2"></div>
                  <div className="h-3 w-1/2 bg-gray-200 rounded"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActivityFeedSkeleton;
