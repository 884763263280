const reportUrl = process.env.NEXT_PUBLIC_REPORT_URL;

const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || 'local';

const spreadsheetWorkerUrl = process.env.NEXT_PUBLIC_SPREADSHEET_WORKER_URL;
const spreadsheetApiKey = process.env.NEXT_PUBLIC_SPREADSHEET_API_KEY;
const elitisApiUrl = process.env.NEXT_PUBLIC_ELITIS_API_URL;
const elitisApiKey = process.env.NEXT_PUBLIC_ELITIS_API_KEY;

const auth0Logout = 'https://auth.startcatalog.com/logout';

const sellerNameMapping = {
  lacompagniedumas: 'dumas',
  'rivedroite-paris': 'rivedroite',
  nogent3etoiles: 'nogent',
  'gamin-tout-terrain': 'gamintoutterrain',
};

export {
  sellerNameMapping,
  spreadsheetWorkerUrl,
  spreadsheetApiKey,
  reportUrl,
  auth0Logout,
  ENVIRONMENT,
  elitisApiUrl,
  elitisApiKey,
};
