import convert, { Unit } from 'convert';

export const getQuantity = ({
  quantity,
  decimal,
  displayUnit,
  storageUnit,
}: {
  decimal: boolean;
  displayUnit: Unit;
  storageUnit: Unit;
  quantity?: number | null;
}): number | undefined => {
  if (!quantity) {
    return undefined;
  }
  if (!decimal) {
    return quantity;
  }

  const convertedQuantity = convert(quantity, storageUnit).to(displayUnit);
  return parseFloat(convertedQuantity.toFixed(2));
};
