import { z } from 'zod';

export const CatalogApiSchema = z.object({
  id: z.string(),
  name: z.string(),
  price_list_id: z.nullable(z.string()),
  all_products: z.boolean(),
});

export type CatalogFromApi = z.infer<typeof CatalogApiSchema>;

export type Catalog = {
  id: string;
  name: string;
  priceListId: string;
  allProducts: boolean;
};
