import { z } from 'zod';
import { AddressApiSchema } from './address';
import { LineItemApiSchema, PaymentApiSchema } from './order';
import { RegionApiSchema } from './region';
import { CompanyApiSchema, CustomerApiSchema } from './company';

export const CartApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
  billing_address_id: z.nullable(z.string()).optional(),
  billing_address: z.nullable(AddressApiSchema).optional(),
  customer_id: z.nullable(z.string()).optional(),
  company_id: z.nullable(z.string()).optional(),
  customer: z.nullable(CustomerApiSchema).optional(),
  company: z.nullable(CompanyApiSchema).optional(),
  delivery_date: z.nullable(z.string()).optional(),
  completed_at: z.nullable(z.string()).optional(),
  context: z
    .nullable(
      z.object({
        ip: z.nullable(z.string()).optional(),
        user_agent: z.nullable(z.string()).optional(),
      }),
    )
    .optional(),
  discount_total: z.nullable(z.number()).optional(),
  email: z.nullable(z.string().email()).optional(),
  idempotency_key: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  object: z.nullable(z.string()).optional(),
  items: z.nullable(z.array(LineItemApiSchema)).optional(),
  payment: z.nullable(PaymentApiSchema).optional(),
  payment_authorized_at: z.nullable(z.string().datetime()).optional(),
  payment_id: z.nullable(z.string()).optional(),
  region: z.nullable(RegionApiSchema).optional(),
  region_id: z.nullable(z.string()).optional(),
  sales_channel_id: z.nullable(z.string()).optional(),
  shipping_address_id: z.nullable(z.string()).optional(),
  shipping_address: z.nullable(AddressApiSchema).optional(),
  shipping_total: z.nullable(z.number()).optional(),
  gift_card_total: z.nullable(z.number()).optional(),
  shipping_tax_total: z.nullable(z.number()).optional(),
  subtotal: z.nullable(z.number()).optional(),
  item_tax_total: z.nullable(z.number()).optional(),
  tax_total: z.nullable(z.number()).optional(),
  total: z.nullable(z.number()).optional(),
  type: z.nullable(z.string()).optional(),
  external_id: z.nullable(z.string()).optional(),
});

export type CartFromApi = z.infer<typeof CartApiSchema>;
