'use client';

import { createContext, useContext } from 'react';
import { useSmartOrdersCount } from '../hooks/use-smart-orders';

interface ISmartOrderCountsContext {
  failedCount: number;
  toCompleteCount: number;
  sentCount: number;
  isLoading: boolean;
}

const initialData: ISmartOrderCountsContext = {
  failedCount: 0,
  toCompleteCount: 0,
  sentCount: 0,
  isLoading: true,
};

export const SmartOrderCountsContext =
  createContext<ISmartOrderCountsContext>(initialData);

export const SmartOrderCountsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    failed: failedCount,
    to_complete: toCompleteCount,
    processed: sentCount,
    isLoading,
  } = useSmartOrdersCount();

  return (
    <SmartOrderCountsContext.Provider
      value={{
        failedCount,
        toCompleteCount,
        sentCount,
        isLoading,
      }}
    >
      {children}
    </SmartOrderCountsContext.Provider>
  );
};

export const useSmartOrderCounts = () => {
  const context = useContext(SmartOrderCountsContext);
  if (context === undefined) {
    throw new Error(
      'useSmartOrderCounts must be used within a SmartOrderCountsProvider',
    );
  }
  return context;
};
