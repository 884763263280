'use-client';

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useSettings } from '@hooks/use-settings';
import Image from 'next/image';
import { FastArrowLeft } from 'iconoir-react';
import { useLeftSidebarContext } from '@context/left-sidebar-context';
import LogoIcon from '../../common/icons/logo';

type LogoAreaProps = {
  companyName: string;
};

const LogoArea = ({ companyName = '' }: LogoAreaProps) => {
  const { isExpanded, toggleSidebar } = useLeftSidebarContext();
  const { sellerLogo } = useSettings();
  const logoComponent = useMemo(() => {
    if (sellerLogo) {
      return (
        <Image
          src={sellerLogo}
          alt="logo"
          width={32}
          height={32}
          className="rounded-lg h8 w-8"
        />
      );
    }
    return <LogoIcon />;
  }, [sellerLogo]);
  return (
    <div className="flex flex-col gap-5 px-2">
      <div className="flex items-center gap-3">
        {isExpanded && logoComponent && (
          <>
            {logoComponent}
            <div className="font-normal text-sm text-gray-900">
              {companyName}
            </div>
          </>
        )}
        <button
          onClick={toggleSidebar}
          className={clsx(
            'transition-transform duration-300 ease-out cursor-pointer w-4 h-4 ml-auto',
            {
              'rotate-180': !isExpanded,
            },
          )}
        >
          <FastArrowLeft className="h-5 w-5 cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default LogoArea;
