'use client';

import React from 'react';
import HomepageFeedback from './homepage-feedback';
import OnboardingBlock from '../onboarding-block';
import NextStepBlock from '../next-step-block';
import ChartsBlock from '../charts-block';

const HomeMainLayout = () => {
  return (
    <div className="flex flex-col gap-6 h-full overflow-y-auto">
      <OnboardingBlock />
      <ChartsBlock />
      <NextStepBlock />
      <HomepageFeedback />
    </div>
  );
};

export default HomeMainLayout;
