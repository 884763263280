import React, { useEffect, useState } from 'react';
import { useActivity } from '@/lib/hooks/use-activity';
import { Activity } from '@/lib/types/activity';
import ActivityRow from './activity-row';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { NavArrowDown } from 'iconoir-react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAmplitudeContext } from '@/lib/context/analytics-context';
import ActivityFeedSkeleton from './activity-feed-skeleton';

const ITEMS_PER_PAGE = 3;

const ActivityFeed = () => {
  const { ct } = useCatalogTranslation('admin_homepage');
  const [allActivities, setAllActivities] = useState<Activity[]>([]);
  const [displayCount, setDisplayCount] = useState(ITEMS_PER_PAGE);
  const [limit, setLimit] = useState(3);
  const { data, isLoading } = useActivity({ limit, expand: 'draft_order' });
  const { trackAmplitudeEvent } = useAmplitudeContext();

  useEffect(() => {
    if (data) {
      setAllActivities((prev) => {
        // Filter out duplicates based on id
        const newActivities = data.filter(
          (newActivity) => !prev.some((p) => p.id === newActivity.id),
        );
        // Combine all activities and sort by creation date (most recent first)
        const combinedActivities = [...newActivities, ...prev];
        return combinedActivities.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      });
    }
  }, [data]);

  // Get the activities to display based on displayCount
  const displayedActivities = allActivities.slice(0, displayCount);

  const handleLoadMore = () => {
    setDisplayCount(displayCount + ITEMS_PER_PAGE);
    setLimit(limit + ITEMS_PER_PAGE);
  };

  const hasMore = allActivities.length < 12 && data?.length === displayCount;

  if (isLoading && allActivities.length === 0) {
    return <ActivityFeedSkeleton />;
  }

  return (
    <div className="homepage-card p-0">
      <h2 className="h2 p-4 pb-0">
        {ct('Homepage.activityFeedTitle', 'Activités')}
      </h2>
      <div className="relative border-b border-gray-200 min-h-[100px]">
        <AnimatePresence mode="sync">
          {displayedActivities.map((activity, index) => (
            <motion.div
              key={activity.id}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{
                duration: 0.2,
                delay: index * 0.1,
                ease: [0.32, 0.72, 0, 1],
              }}
              onClick={() => {
                trackAmplitudeEvent?.('home-activity-feed-card-clicked', {
                  activity_type: activity.type,
                });
              }}
            >
              <ActivityRow activity={activity} />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      {isLoading && allActivities.length > 0 && (
        <div className="text-center py-2">Loading...</div>
      )}

      {!isLoading && hasMore && (
        <button
          onClick={() => {
            handleLoadMore();
            trackAmplitudeEvent?.('home-activity-feed-see-more-clicked');
          }}
          className="w-full pb-4 text-sm text-gray-700 hover:text-gray-900 flex items-center justify-center gap-2"
        >
          {ct('Homepage.activityFeedLoadMore', 'Afficher plus')}
          <NavArrowDown />
        </button>
      )}
    </div>
  );
};

export default ActivityFeed;
