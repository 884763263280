import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fr, enUS } from 'date-fns/locale';

export const useLocalLanguage = () => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState<Locale>(fr);

  useEffect(() => {
    if (!i18n.language) return;
    switch (i18n.language) {
      case 'en':
        setLocale(enUS);
        break;
      case 'fr':
        setLocale(fr);
        break;
    }
  }, [i18n.language]);

  return locale;
};
