'use client';

const OnboardingBlockSkeleton = () => {
  return (
    <div className="homepage-card animate-pulse">
      <div className="h-8 w-1/3 bg-gray-200 rounded mb-4"></div>
      <div className="h-4 w-2/3 bg-gray-200 rounded mb-4"></div>
      <div className="h-2 w-full bg-gray-200 rounded mb-2"></div>
      <div className="flex justify-between">
        <div className="h-4 w-1/4 bg-gray-200 rounded"></div>
        <div className="h-4 w-1/6 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};

export default OnboardingBlockSkeleton;
