import { useMemo } from 'react';
import { useSmartOrderCounts } from '../context/smart-order-counts-context';

interface OnboardingData {
  onboardingThreshold: number;
  onboardingProgression: number;
  sentCount: number;
}

export const useOnboarding = (): OnboardingData => {
  const { sentCount } = useSmartOrderCounts();
  const onboardingThreshold = 100;

  const onboardingProgression = useMemo(() => {
    return Math.min(Math.round((sentCount / onboardingThreshold) * 100), 100);
  }, [onboardingThreshold, sentCount]);

  return {
    onboardingThreshold,
    onboardingProgression,
    sentCount,
  };
};
