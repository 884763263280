import { z } from 'zod';

export const SettingApiSchema = z.object({
  name: z.string(),
  value: z.string(),
  description: z.nullable(z.string()).optional(),
});

export type SettingFromApi = z.infer<typeof SettingApiSchema>;

export type Setting = {
  name: string;
  value: string;
  description?: string | null;
};
