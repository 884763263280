import { z } from 'zod';
import { UserApiSchema, User } from './user';

export enum ActivityTypes {
  SMART_ORDER_RECEIVED = 'smart_order_received',
  SMART_ORDER_TO_COMPLETE = 'smart_order_to_complete',
  SMART_ORDER_FAILED = 'smart_order_failed',
  SMART_ORDER_SENT_TO_ERP = 'smart_order_sent_to_erp',
  SMART_ORDER_COMPLETED = 'smart_order_completed',
  SMART_ORDER_CANCELED = 'smart_order_canceled',
}

export const ActivityApiSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(ActivityTypes),
  created_at: z.string(),
  draft_order_id: z.nullable(z.string()),
  order_id: z.nullable(z.string()),
  agent_id: z.nullable(z.string()),
  draft_order: z
    .nullable(
      z.object({
        display_id: z.nullable(z.union([z.string(), z.number()])).optional(),
        order_id: z.nullable(z.string()).optional(),
        deleted_at: z.nullable(z.string()).optional(),
      }),
    )
    .optional(),
  agent: z.nullable(UserApiSchema).optional(),
});

export type ActivityFromApi = z.infer<typeof ActivityApiSchema>;

export type Activity = {
  id: string;
  type: ActivityTypes;
  createdAt: Date;
  draftOrderId: string | null;
  orderId: string | null;
  agentId: string | null;
  smartOrderDisplayId: string | number | null;
  agent: ActivityAgent | null;
  isCanceled: boolean;
};

export type ActivityAgent = User & {
  fullName: string;
  initials: string;
  colorClass: string;
};
