import { QueryKey, useQuery } from '@tanstack/react-query';
import { Length, Mass, Volume } from 'convert';
import { SmartOrderValidationRules } from '../types/smart-orders';
import { useSettingsData } from '../data/settings/data';
type SettingsFromApi = {
  name: string;
  value: string;
  description: string;
};

export type AdminSettings = {
  sellerLogo: string;
  lengthUnit: Length;
  massUnit: Mass;
  volumeUnit: Volume;
  productsSpreadsheetId?: string;
  categoriesSpreadsheetId?: string;
  catalogsSpreadsheetId?: string;
  clientsSpreadsheetId?: string;
  sellerNameTrimLow: string;
  createCompanyEnabled: boolean;
  newCustomerDelay: number;
  stockLevelEnabled: boolean;
  outOfStockEnabled: boolean;
  quantityLeftEnabled: boolean;
  limitedStockEnabled: boolean;
  limitedStockThreshold: number | null;
  displayPaymentStatus: boolean;
  hideTaxesAndShippingCosts: boolean;
  paymentMethods: string[];
  editSmartorderItems: boolean;
  editSmartorderPrices: boolean;
  hideDeactivatedCompanies: boolean;
  smartOrderValidationRules: SmartOrderValidationRules;
  displayActionsNavigationIcon: boolean;
  enableQuoteRequestDocument: boolean;
  orderCtaEnabled: boolean;
};

export const buildSettings = (data: unknown): AdminSettings | undefined => {
  let sellerLogo = '';
  let lengthUnit: Length = 'mm';
  let massUnit: Mass = 'g';
  let volumeUnit: Volume = 'mL';
  let productsSpreadsheetId: string | undefined = undefined;
  let categoriesSpreadsheetId: string | undefined = undefined;
  let catalogsSpreadsheetId: string | undefined = undefined;
  let clientsSpreadsheetId: string | undefined = undefined;
  let sellerNameTrimLow = '';
  let createCompanyEnabled = false;
  let newCustomerDelay = 30;
  let stockLevelEnabled = false;
  let outOfStockEnabled = false;
  let quantityLeftEnabled = false;
  let limitedStockEnabled = false;
  let limitedStockThreshold = null;
  let displayPaymentStatus = false;
  let hideTaxesAndShippingCosts = false;
  const paymentMethods: string[] = [];
  let editSmartorderItems = false;
  let editSmartorderPrices = false;
  let hideDeactivatedCompanies = false;
  let smartOrderValidationRules: SmartOrderValidationRules = {};
  let displayActionsNavigationIcon = false;
  let enableQuoteRequestDocument = false;
  let orderCtaEnabled = false;
  if (
    typeof data === 'object' &&
    Object.prototype.hasOwnProperty.call(data, 'settings')
  ) {
    const { settings } = data as { settings: SettingsFromApi[] };
    for (const setting of settings) {
      switch (setting.name) {
        case 'seller_logo':
          sellerLogo = setting.value;
          break;
        case 'seller_name':
          sellerNameTrimLow = setting.value;
          break;
        case 'display_length_unit':
          lengthUnit = setting.value as Length;
          break;
        case 'display_mass_unit':
          massUnit = setting.value as Mass;
          break;
        case 'display_volume_unit':
          volumeUnit = setting.value as Volume;
          break;
        case 'products_spreadsheet_id':
          productsSpreadsheetId = setting.value;
          break;
        case 'categories_spreadsheet_id':
          categoriesSpreadsheetId = setting.value;
          break;
        case 'catalogs_spreadsheet_id':
          catalogsSpreadsheetId = setting.value;
          break;
        case 'clients_spreadsheet_id':
          clientsSpreadsheetId = setting.value;
          break;
        case 'admin_create_company_enabled':
          createCompanyEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'new_customer_delay':
          newCustomerDelay = parseInt(setting.value);
          break;
        case 'stock_level_enabled':
          stockLevelEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'out_of_stock_enabled':
          outOfStockEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'quantity_left_enabled':
          quantityLeftEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'limited_stock_enabled':
          limitedStockEnabled = JSON.parse(setting.value.toLowerCase());
          break;
        case 'limited_stock_threshold':
          limitedStockThreshold =
            setting.value === 'null' ? null : parseInt(setting.value);
          break;
        case 'display_payment_status':
          displayPaymentStatus = JSON.parse(setting.value.toLowerCase());
          break;
        case 'hide_taxes_and_shipping_costs':
          hideTaxesAndShippingCosts = JSON.parse(setting.value.toLowerCase());
          break;
        case 'payment_methods':
          paymentMethods.push(
            ...setting.value
              .split(',')
              .map((method) => method.trim())
              .filter(Boolean),
          );
          break;
        case 'edit_smartorder_items':
          editSmartorderItems = JSON.parse(setting.value.toLowerCase());
          break;
        case 'edit_smartorder_prices':
          editSmartorderPrices = JSON.parse(setting.value.toLowerCase());
          break;
        case 'hide_deactivated_companies':
          hideDeactivatedCompanies = JSON.parse(setting.value.toLowerCase());
          break;
        case 'smart_order_validation_rules':
          smartOrderValidationRules = JSON.parse(
            setting.value.replace(/\\/g, '').replace(/'/g, '"'),
          );
          break;
        case 'display_actions_navigation_icon':
          displayActionsNavigationIcon = JSON.parse(
            setting.value.toLowerCase(),
          );
          break;
        case 'enable_quote_request_document':
          enableQuoteRequestDocument = JSON.parse(setting.value.toLowerCase());
          break;
        case 'order_cta_enabled':
          orderCtaEnabled = JSON.parse(setting.value.toLowerCase());
          break;
      }
    }
    return {
      sellerLogo,
      lengthUnit,
      massUnit,
      volumeUnit,
      productsSpreadsheetId,
      categoriesSpreadsheetId,
      catalogsSpreadsheetId,
      clientsSpreadsheetId,
      sellerNameTrimLow,
      createCompanyEnabled,
      newCustomerDelay,
      stockLevelEnabled,
      outOfStockEnabled,
      quantityLeftEnabled,
      limitedStockEnabled,
      limitedStockThreshold,
      displayPaymentStatus,
      hideTaxesAndShippingCosts,
      paymentMethods,
      editSmartorderItems,
      editSmartorderPrices,
      hideDeactivatedCompanies,
      smartOrderValidationRules,
      displayActionsNavigationIcon,
      enableQuoteRequestDocument,
      orderCtaEnabled,
    } as AdminSettings;
  }
  return undefined;
};

export const useSettings = () => {
  const { fetchSettingsForReactQuery } = useSettingsData();
  const queryKey: QueryKey = ['settings'];
  const { data: settings } = useQuery({
    queryKey,
    queryFn: fetchSettingsForReactQuery,
    select: buildSettings,
  });

  return { ...settings } as const;
};
