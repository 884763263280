'use client';

import React from 'react';
import HomeMainLayout from './main-layout/main-layout';
import ActivityFeed from './activity-feed/activity-feed';
import HomepageHeader from './main-layout/homepage-header';

const HomeTemplate = () => {
  return (
    <div className="h-full w-full overflow-auto">
      <HomepageHeader />
      <div className="p-4 flex flex-col gap-4 lg:max-w-[900px] xl:max-w-[1200px] 2xl:w-full 2xl:max-w-[1400px] mx-auto xl:flex-row 2xl:gap-8 2xl:p-8">
        <div className="w-full 2xl:flex-1 xl:w-3/4 2xl:max-w-[1024px] 2xl:ml-auto">
          <HomeMainLayout />
        </div>

        <div className="w-full h-auto 2xl:basis-[330px] xl:w-1/4 2xl:w-[330px]">
          <ActivityFeed />
        </div>
      </div>
    </div>
  );
};

export default HomeTemplate;
