'use client';

const HomepageFeedbackSkeleton = () => {
  return (
    <div className="homepage-card animate-pulse">
      <div className="h-8 w-1/2 bg-gray-200 rounded mb-4"></div>
      <div className="h-20 w-full bg-gray-200 rounded"></div>
    </div>
  );
};

export default HomepageFeedbackSkeleton;
