'use client';

import React from 'react';

const ProgressBar = ({ progression }: { progression: number }) => {
  return (
    <div>
      <div className="overflow-hidden rounded-full bg-gray-200">
        <div
          style={{ width: `${progression}%` }}
          className="h-2 rounded-full bg-secondary-500 transition-all duration-500 ease-in-out"
        />
      </div>
    </div>
  );
};

export default ProgressBar;
