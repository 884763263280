import { User, UserFromApi } from '../types/user';

export const buildUser = (user?: UserFromApi): User | undefined => {
  if (!user) {
    return undefined;
  }
  const label =
    `${user.first_name || ''} ${user.last_name || ''}`.trim() || user.email;
  return {
    id: user.id,
    label,
    email: user.email,
    firstName: user.first_name!,
    lastName: user.last_name!,
    role: user.role!,
    metadata: user.metadata || {},
  };
};
