'use client';
import { useAccount } from '@/lib/context/account-context';
import { useTenant } from '@/lib/context/tenant-context';
import { useCatalogTranslation } from '@/lib/hooks/use-catalog-translations';
import { useAmplitudeContext } from '@/lib/context/analytics-context';
import Link from 'next/link';
import Button from '../../common/button';
import { useHomepageContext } from '@context/homepage-context';
import HomepageFeedbackSkeleton from './homepage-feedback-skeleton';

const HomepageFeedback = () => {
  const { ct } = useCatalogTranslation('admin_homepage');
  const { ...loggedUser } = useAccount();
  const { trackAmplitudeEvent } = useAmplitudeContext();
  const { isLoading } = useHomepageContext();
  const { tenantId } = useTenant();

  if (isLoading) {
    return <HomepageFeedbackSkeleton />;
  }

  return (
    <div className="homepage-card">
      <h2 className="h2 text-gray-900">
        {ct(
          'Homepage.feedbackTitle',
          "Aidez nous à améliorer l'efficacité d'Arthur",
        )}
      </h2>
      <p className="text-gray-700 text-sm">
        {ct(
          'Homepage.feedbackSubtitle',
          'Partagez avec nous les détails des anomalies rencontrées lors des commandes envoyées à votre ERP, afin que nous puissions ajuster et perfectionner le traitement des commandes par Arthur (produits manquants, références incorrectes, ou informations mal détectées.)',
        )}
      </p>
      <div className="flex justify-start">
        <Button
          color="primary"
          size="sm"
          onClick={() => trackAmplitudeEvent?.('home-feedback-form-clicked')}
        >
          <Link
            href={`https://tally.so/r/wbKEg2?email=${loggedUser.email}&seller=${tenantId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {ct('Homepage.feedbackCTA', 'Faire un retour')}
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default HomepageFeedback;
