'use client';

import React from 'react';
import ProgressBar from '../../common/progress-bar';
import { useHomepageContext } from '@context/homepage-context';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import CustomTooltip from '../../common/tooltip';
import { InfoCircle } from 'iconoir-react';
import OnboardingBlockSkeleton from './onboarding-block-skeleton';

const TOOLTIP_DEFAULT_CONTENT =
  "Pendant ces premières semaines, Arthur apprend à traiter vos commandes avec précision. Il est normal que des erreurs se produisent occasionnellement pendant cette phase d'apprentissage. Aidez Arthur à s'améliorer en continuant à envoyer des commandes, votre contribution est essentielle pour le rendre encore plus performant";

const OnboardingBlock = () => {
  const { ct } = useCatalogTranslation(['admin_homepage', 'admin_common']);
  const { onboardingProgression, onboardingThreshold, sentCount, isLoading } =
    useHomepageContext();

  if (onboardingProgression >= onboardingThreshold) {
    return null;
  }

  if (isLoading) {
    return <OnboardingBlockSkeleton />;
  }

  return (
    <div className="homepage-card">
      <div>
        <div className="flex items-center justify-between gap-3">
          <h2 className="h2">
            {ct('Homepage.onboardingTitle', "Aidez Arthur à s'améliorer")}
          </h2>
          <CustomTooltip
            placement="bottom"
            content={ct('Homepage.oboardingTooltip', TOOLTIP_DEFAULT_CONTENT)}
          >
            <InfoCircle className="w-4 h-4 text-gray-700" />
          </CustomTooltip>
        </div>
        <p className="text-sm text-gray-700">
          {ct(
            'Homepage.onboardingSubtitle',
            'Bravo, Arthur apprend déjà de vos {{count}} commandes !',
            {
              count: sentCount,
            },
          )}
        </p>
      </div>
      <div>
        <ProgressBar progression={onboardingProgression} />
        <div className="flex items-center justify-between w-full mt-[10px] text-sm text-gray-700">
          <div>
            {ct(
              'CommonSidebar.onboardingOrderToRaise',
              'Traiter {{count}} commandes',
              { count: onboardingThreshold },
            )}
          </div>
          <div className="text-gray-900">{`${onboardingProgression}%`}</div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingBlock;
