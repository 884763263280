import { Language } from '../types/common-types';

export const LIMIT = 15;

export const LANGUAGES: Language[] = [
  {
    label: 'English',
    shortLabel: 'EN - US',
    countryCode: 'US',
  },
  {
    label: 'French',
    shortLabel: 'FR',
    countryCode: 'FR',
  },
  {
    label: 'German',
    shortLabel: 'DE',
    countryCode: 'DE',
  },
  {
    label: 'Dutch',
    shortLabel: 'NL',
    countryCode: 'NL',
  },
  {
    label: 'Italian',
    shortLabel: 'IT',
    countryCode: 'IT',
  },
  {
    label: 'Spanish',
    shortLabel: 'ES',
    countryCode: 'ES',
  },
];
