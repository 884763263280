'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AccountProvider } from './account-context';
import { AmplitudeContextProvider } from './analytics-context';

const queryClient = new QueryClient();

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AmplitudeContextProvider>
        <AccountProvider>{children}</AccountProvider>
      </AmplitudeContextProvider>
    </QueryClientProvider>
  );
};

export default Providers;
