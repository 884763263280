'use client';

const NextStepBlockSkeleton = () => {
  return (
    <div className="homepage-card animate-pulse">
      <div className="h-8 w-1/3 bg-gray-200 rounded mb-4"></div>
      <div className="space-y-4">
        <div className="h-16 w-full bg-gray-200 rounded"></div>
        <div className="h-16 w-full bg-gray-200 rounded"></div>
        <div className="h-16 w-full bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};

export default NextStepBlockSkeleton;
