'use client';

const HomepageHeaderSkeleton = () => {
  return (
    <header className="p-8 pb-0 lg:max-w-[900px] xl:max-w-[1200px] 2xl:w-full 2xl:max-w-[1400px] mx-auto">
      <div className="animate-pulse">
        <div className="h-8 w-1/3 bg-gray-200 rounded mb-2"></div>
        <div className="h-4 w-1/2 bg-gray-200 rounded"></div>
      </div>
    </header>
  );
};

export default HomepageHeaderSkeleton;
